$mobile-width: 480px;
$tablet-width: 768px;
$desktop-width: 1024px;
$retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";

@mixin mobile {
  @media only screen and (max-device-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin mobileLandscape {
  @media only screen and (max-width: 900px) and (orientation: landscape) {
    @content;
  }
}

@mixin tabLandscape {
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
   @content;
  }
}

@mixin smallDevice {
  @media only screen and (max-width: 400px) {
    @content;
  }
}
