@import "./../../styles/colors";
@import "./../../styles/responsive";


.header {
  background: $secondary;
  color: white;

  &.privacy {
    background: #3B3B4C;
  }

  &.faq {
    background: #35272F 30%;
  }
}

.container {
  height: auto;
  background: linear-gradient(to bottom, $secondary 30%, white 30%);
  padding: 8% 10%;


  &.privacy {
    background: linear-gradient(to bottom, #3B3B4C 30%, white 30%);
  }

  &.faq {
    background: linear-gradient(to bottom, #35272F 30%, white 30%);
  }

  h1, h4 {
    color: white;
    line-height: 3.5rem;
  }

  .content {
    background-color: white;
    padding: 3%;
    border-radius: 8px;
    color: #424141;
    font-size: 0.8rem;
    line-height: 1.5rem;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);

    .title {
      font-weight: bold;
    }

    .desc {
      margin-bottom: 1rem;
    }

    .number {
      list-style: decimal;
      margin-left: 1%;
    }
  }

  @include mobile {
    padding-top: 25%;
    h1, h4 { 
      text-align: center;
    }
  }

  @include mobileLandscape { 
    padding-top: 13%;

  }
}
