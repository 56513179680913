@import "./../../../styles/responsive";
@import "../../../styles/colors";

.menuContainer {
  .mobNav {
    color: #271651;
    font-weight: bold;
  }

  .sHidden {
    display: flex;
  }

  .sShow {
    display: none;
  }

  .border {
    width: 80%;
    height: 5px;
    background-color: $secondary;
    border-radius: 4px;
    margin-top: 7px;

  }

  ul {
    justify-content: center;
    align-items: center;
    list-style: none;
    cursor: pointer;
    gap: 3.6rem;
    display: flex;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .freeTrialBtn {
    background-color: #f6b500;
    padding: 0.6rem 1rem;
    border-radius: 0;
    color: black;

    @include mobile {
      color: white;
    }
  }

  @include mobile {
    .sHidden {
      display: none;
    }

    .sShow {
      display: block;
      flex-direction: column;
    }

    ul {
      position: absolute;
      width: 100%;
      left: 0;
      background-color: $secondary;
      color: white;

      li {
        padding: 1rem;
      }
    }
  }
}
