@import "../../styles/variables";
@import "../../styles/colors";

.button {
  font-family: $font-poppins;
  color: white;
  padding: 0.6rem 4rem;
  border: 0;
  font-family: $font-poppins;
  font-size: 1rem;
  border-radius: 20px;
  background-color: #EF5DA8;
  cursor: pointer;

  &.secondary {
    background-color: $secondary;
  }
}
