@import "./../../styles/responsive";

.header {
    position: sticky;
    padding: 1rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: rgba(255, 255, 255, 1);
    z-index: 10;

    @include mobile {
      padding: 0.4rem;
    }

    @include tablet {
      padding: 0.4rem;
    }
    
  .logo-container {
    display: flex;
    align-items: center;

  }

  &.fixed {
    position: fixed;
  }
}
