@import "../../styles/variables";
@import "../../styles/colors";
@import "../../styles/responsive";

.footer {
  display: flex;
  background-color: #3b3b4c;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.16);
  flex-direction: row;
  color: white;
  padding: 1.5% 4%;
  justify-content: center;
  align-items: center;

  .quick {
    display: flex;
    flex-direction: row;
    flex: 1;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;

    > div {
      padding-left: 5%;
    }
  }

  .social {
    width: 30%;
    img {
      padding-left: 10%;
      cursor: pointer;
    }
  }

  @include tablet {
    flex-direction: column;
    padding: 10% 0;

    .quick {
      width: 100%;
      justify-content: space-around;
    }

    .social {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      img {
        padding: 0;
        padding-top: 10%;
        padding-right: 10%;
      }
    }
  }
}
