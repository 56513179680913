@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&family=Poppins:wght@400;500&display=swap');
@import "./styles/variables";
@import-normalize;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-poppins;
  font-weight: 400;
  transition: all .4s ease;
}

html, body, #root {
  width: 100%;
  height: 100%;
  font-size: 16px;
  scroll-behavior: smooth;
  position: relative;
}

.form-control {
  margin: 1rem 0;
  width: 100%;

}

.form-label {
  margin: 0.5rem 0;
  display: inline-block;

  .required {
    color: red;
  }
}

.d-flex {
  display: flex;
}

.d-row {
  flex-direction: row;
}

.j-space-between {
  justify-content: space-between !important;
}

.d-col {
  flex-direction: column;
}

.txt-right {
  text-align: end;
}

.p-3 {
  padding: 3%;
}

.mt-10 {
  margin-top: 10%;
}

.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  cursor: pointer;
  color: #2082f4;
}


.fixed {
  position: fixed;
}

.w-100 {
  width: 100%;
}

.external {
    text-decoration: none;
    color: black;
}